import React from "react";

const Modal = props => {
  return (
    <div>
      <div
        className="modal fade"
        id="quoteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary text-white">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                {props.quote} Quote
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="text-white" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <form name="Quote" method="POST">
              <input type="hidden" name="form-name" value="Quote" />
              <input type="hidden" value={props.quote} name="quoteType" />
              <div className="modal-body" />
              <div className="container">
                <div className="row form-group">
                  <div className="col">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control mr-auto"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="business"
                    className="form-control"
                    placeholder="Business (not required)"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-group">
                  {props.quote === "Design" ? (
                    <select
                      name="plan[]"
                      className="form-control"
                      placeholder="Select Plan"
                      required
                    >
                      <option value="" disabled selected>
                        Select your plan
                      </option>
                      <option value="Basic">Basic</option>
                      <option value="Pro">Pro</option>
                      <option value="Premium">Premium</option>
                    </select>
                  ) : (
                    <select
                      name="plan[]"
                      placeholder="Select Plan"
                      className="form-control"
                      required
                    >
                      <option value="" disabled selected>
                        Select your plan
                      </option>
                      <option value="Personal">Personal</option>
                      <option value="Business">Business</option>
                      <option value="Business Premium">Business Premium</option>
                    </select>
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Provide us with information regarding your needs."
                    name="description"
                    id=""
                    rows="5"
                    required
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
