import React, { Component, Fragment } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Info from "./components/Home/Info";
import Products from "./components/Home/Products";
import Modal from "./components/Home/Modal";
import Contact from "./components/Home/Contact";
import Footer from "./components/Footer";
import packages from "./components/Home/packages.json";

class App extends Component {
  state = {
    activeClass: "",
    product: "Design",
    cards: packages.design,
    modal: "MODAL"
  };

  componentDidMount() {
    this.getScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll");
  }

  getScroll = () => {
    window.addEventListener("scroll", () => {
      let activeClass = "scrolled";
      if (window.scrollY === 0) {
        activeClass = "";
      }
      this.setState({ activeClass });
    });
  };

  getProduct = selectedProduct => {
    let { product, cards } = this.state;
    if (selectedProduct === product) {
      product = selectedProduct;
    } else {
      product = selectedProduct;
    }

    if (product === "Design") {
      cards = packages.design;
    } else if (product === "Website") {
      cards = packages.site;
    }
    this.setState({ product, cards });
  };

  render() {
    const { activeClass, product, cards, modal } = this.state;
    return (
      <Fragment>
        <Navbar activeClass={activeClass} />
        <Home />
        <Info />
        <Products
          product={product}
          cards={cards}
          getProduct={this.getProduct}
          modal={modal}
        />
        <Modal quote={product} info={cards} />
        <Contact />
        <Footer />
      </Fragment>
    );
  }
}

export default App;
