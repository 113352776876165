import React from "react";

const Home = () => {
  return (
    <div>
      <div id="home" className="jumbotron jumbotron-fluid mt-5 mt-sm-0">
        <div className="container text-center text-white mt-5">
          <h1 className="title display-3 d-none d-sm-block">vcmedia</h1>
          <h1 className="title display-4 d-sm-none">vcmedia</h1>
          <p className="lead mb-4">Innovation for the modern web</p>
          <hr style={{ borderColor: "rgba(255, 255, 255, 0.29)" }} />
          <p>It all starts with an idea. Let us bring it to life.</p>
          <div className="row">
            <div className="col">
              <a href="#info" className="btn btn-info">
                Find Out More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="card border-0 shadow">
              <img
                src="https://images.unsplash.com/photo-1542744094-3a31f272c490?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                alt=""
                className="card-img-top card-image d-none d-sm-block"
              />
              <div className="card-body card-body-size">
                <h4 className="card-title">
                  <strong>Design</strong>
                </h4>
                <h6 className="card-subtitle text-muted">
                  Behind every beautiful site there is a seamless design
                </h6>
                <hr />
                <p className="card-text mb-lg-4">
                  Utilizing modern UX/UI principles, we build an experience that
                  is harmonious across all devices.
                </p>
                <br className="d-none d-lg-block d-xl-none" />
                <a
                  href="#products"
                  className="btn btn-primary btn-block text-center mt-auto"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="card border-0 shadow">
              <img
                src="https://images.unsplash.com/photo-1504639725590-34d0984388bd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
                alt=""
                className="card-img-top card-image d-none d-sm-block"
              />
              <div className="card-body card-body-size">
                <h4 className="card-title">Build</h4>
                <h6 className="card-subtitle text-muted">
                  Custom built to convert clients
                </h6>
                <hr />
                <p className="card-text">
                  Whether business or personal, we build beautiful, interactive
                  sites that engage clients and convert them to customers.
                </p>
                <br className="d-none d-lg-block" />
                <a
                  href="#info"
                  className="btn btn-secondary btn-block text-center mt-auto"
                >
                  More Info
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="card border-0 shadow">
              <img
                src="https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1491&q=80"
                alt=""
                className="card-img-top card-image d-none d-sm-block"
              />
              <div className="card-body card-body-size">
                <h4 className="card-title">Sustain</h4>
                <h6 className="card-subtitle text-muted">
                  Customer support 24/7
                </h6>
                <hr />
                <p className="card-text">
                  Keep your site up to date and secure after you launch, contact
                  us to find out more.
                </p>
                <br className="d-none d-lg-block" />
                <br className="d-none d-lg-block" />
                <a
                  href="#contact"
                  className="btn btn-dark btn-block text-center"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
