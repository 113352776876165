import React from "react";

const Contact = () => {
  return (
    <div
      className="jumbotron jumbotron-fluid bg-dark text-white mb-0 pt-5"
      id="contact"
    >
      <div className="container pt-4">
        <h2>Contact Us</h2>
        <p className="lead text-muted mb-5">
          Have a question or want a custom quote? Contact us below
        </p>
        <form name="contact" method="post" className="contact">
          <input type="hidden" name="form-name" value="contact" />
          <div className="row form-group">
            <div className="col-lg-3 col-md-6 mb-3">
              <input
                type="text"
                name="firstName"
                className="form-control mr-auto"
                placeholder="First Name"
                required
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <input
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Last Name"
                required
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                required
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <input
                type="text"
                name="business"
                className="form-control"
                placeholder="Business (not required)"
              />
            </div>
          </div>

          <div className="form-group mb-4">
            <textarea
              className="form-control"
              placeholder="Provide us with information regarding your needs."
              name="description"
              id=""
              rows="8"
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-outline-info btn-block"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
