import React from "react";
import ProductCard from "./ProductCard.js";

const ProductCards = props => {
  return (
    <div className="row">
      <ProductCard info={props.cards.basic} />
      <ProductCard info={props.cards.pro} />
      <ProductCard info={props.cards.custom} />
    </div>
  );
};

export default ProductCards;
