import React from "react";

const Info = () => {
  return (
    <div id="info" className="jumbotron jumbotron-fluid bg-dark text-white">
      <div className="container pt-5 pt-md-3">
        <h2 className="">What we do</h2>
        <p className="lead text-muted mb-5">
          We turn your ideas into reality by streamlining the web development
          process.
        </p>
        <div className="row">
          <div className="col-lg-6 col-xl-4  border-secondary">
            <div className="row p-4">
              <div className="col-lg-2 col-xl-3 col-sm-2 col-12 text-center">
                <h5>
                  <i className="fas fa-pencil-alt fa-2x icon" />
                </h5>
              </div>
              <div className="col-xl-9 col-sm-10 col-12">
                <h4 className="mt-2">Sketch</h4>
                <p>
                  Bring us your ideas and we'll sketch a wireframe site to fit
                  your liking. We'll go over navigation and features here.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4  border-secondary">
            <div className="row p-4">
              <div className="col-lg-2 col-xl-3 col-sm-2 col-12 text-center">
                <h4>
                  <i className="fab fa-sketch fa-2x icon" />
                </h4>
              </div>
              <div className="col-xl-9 col-sm-10 col-12">
                <h4 className="mt-2">Design</h4>
                <p>
                  This is where we build the overall theme of the site. Colors,
                  fonts, and components are used to begin to develop a
                  prototype.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4  border-secondary">
            <div className="row p-4">
              <div className="col-lg-2 col-xl-3 col-sm-2 col-12 text-center">
                <h5>
                  <i className="fas fa-mouse-pointer fa-2x icon pl-1" />
                </h5>
              </div>
              <div className="col-xl-9 col-sm-10 col-12">
                <h4 className="mt-2">Interact</h4>
                <p>
                  Using Adobe Xd, we build an interactive user experience of
                  your site. We can tweak and adjust until it's perfect!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4  border-secondary">
            <div className="row p-4">
              <div className="col-lg-2 col-xl-3 col-sm-2 col-12 text-center">
                <h5>
                  <i className="fas fa-code fa-2x icon" />
                </h5>
              </div>
              <div className="col-xl-9 col-sm-10 col-12">
                <h4 className="mt-2">Develop</h4>
                <p>
                  Develop a site that attracts and converts clients. Using the
                  latest SEO tools, we'll help generate new online leads and in
                  turn, more profit.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4  border-secondary">
            <div className="row p-4">
              <div className="col-lg-2 col-xl-3 col-sm-2 col-12 text-center">
                <h5>
                  <i className="fas fa-lock fa-2x icon" />
                </h5>
              </div>
              <div className="col-xl-9 col-sm-10 col-12">
                <h4 className="mt-2">Secure</h4>
                <p>
                  We manage and secure all of our domains with HTTPS and back up
                  your site in the cloud.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4  border-secondary">
            <div className="row p-4">
              <div className="col-lg-2 col-xl-3 col-sm-2 col-12 text-center">
                <h5>
                  <i className="fas fa-wrench fa-2x icon" />
                </h5>
              </div>
              <div className="col-xl-9 col-sm-10 col-12">
                <h4 className="mt-2">Maintain</h4>
                <p>
                  With a maintenance package, you'll be able to update
                  information and we'll keep your site up to date with the
                  latest security protocols.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
