import React from "react";

const ProductCard = props => {
  return (
    <div className="col-lg-4 col-md-6 mb-3 mb-lg-0">
      <div className="card mb-4 shadow border-0 rounded">
        <div className="card-header bg-primary text-white text-center">
          <h4 className="my-0 font-weight-normal">{props.info.title}</h4>
        </div>
        <div className="card-body">
          <div className="text-center">
            <h2 className="card-title pricing-card-title">
              {props.info.estimate}
            </h2>
            <p className="lead text-muted">{props.info.description}</p>
          </div>
          <ul className="fa-ul">
            {props.info.classes ? (
              props.info.classes.map(list => {
                return (
                  <li key={list.name} className="mb-2 h5">
                    <span className="fa-li">
                      <i className={`fas fa-${list.icon} text-${list.color}`} />
                    </span>
                    <span className={`text-${list.text}`}>{list.name}</span>
                  </li>
                );
              })
            ) : (
              <li>Nothing here</li>
            )}
          </ul>

          {props.info.monthly === null ? (
            ""
          ) : (
            <div>
              <p className="text-muted">
                *Monthly hosting charge of {props.info.monthly}
              </p>
              <p className="text-muted">*{props.info.retainer}</p>
            </div>
          )}
          <button
            type="button"
            className="btn btn-lg btn-block btn-outline-primary"
            data-toggle="modal"
            data-target="#quoteModal"
          >
            Get Quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
