import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-right">
        <a href="https://www.instagram.com/vcmedia_/">
          <i className="fab fa-instagram fa-2x" />
        </a>
      </div>

      <div className="footer-left">
        <p className="footer-links">
          <a href="#home">Home</a> · <a href="#info">Info</a> ·{" "}
          <a href="#products">Products</a> · <a href="#contact">Contact</a>
        </p>

        <p className="text-white">VMEDIA LLC &copy; 2019</p>
      </div>
    </footer>
  );
};

export default Footer;
