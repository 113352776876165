import React from "react";
import ProductCards from "./ProductCards";

const Products = props => {
  return (
    <div className="container pt-5 mb-5" id="products">
      <h2 className="display-4 mt-3 mt-md-0">Products</h2>
      <p className="lead mb-4">
        Select the plan that best fit your needs. See which pricing option is
        best for your needs and get a free quote!
      </p>
      <ul className="nav nav-tabs mb-lg-4 mb-3" id="tabs-tab" role="tablist">
        <li className="nav-item">
          <a
            onClick={() => {
              props.getProduct("Design");
            }}
            className="nav-link active"
            id="tabs-design-tab"
            data-toggle="pill"
            href="#tabs-design"
            role="tab"
            aria-controls="tabs-design"
            aria-selected="true"
          >
            Design
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => {
              props.getProduct("Website");
            }}
            className="nav-link"
            id="tabs-site-tab"
            data-toggle="pill"
            href="#tabs-site"
            role="tab"
            aria-controls="tabs-site"
            aria-selected="false"
          >
            Website
          </a>
        </li>
      </ul>
      <div className="tab-content" id="tabs-tabContent">
        <div
          className="tab-pane fade show active"
          id="tabs-design"
          role="tabpanel"
          aria-labelledby="tabs-design-tab"
        >
          <ProductCards product={props.product} cards={props.cards} />
        </div>
        <div
          className="tab-pane fade"
          id="tabs-site"
          role="tabpanel"
          aria-labelledby="tabs-site-tab"
        >
          <ProductCards product={props.product} cards={props.cards} />
        </div>
        <div
          className="tab-pane fade"
          id="tabs-combined"
          role="tabpanel"
          aria-labelledby="tabs-combined-tab"
        >
          <ProductCards product={props.product} cards={props.cards} />
        </div>
      </div>
    </div>
  );
};

export default Products;
