import React from "react";

const Navbar = props => {
  return (
    <nav
      id="mainNav"
      className={`navbar navbar-dark bg-md-primary navbar-expand-md row fixed-top ${
        props.activeClass
      }`}
    >
      <a href="#home" className="navbar-brand title mt-1 col-2">
        vcmedia
      </a>
      <button
        className="navbar-toggler mr-2"
        type="button"
        data-toggle="collapse"
        data-target="#vcNav"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse col-md-8 pr-5 justify-content-center"
        id="vcNav"
      >
        <div className="navbar-nav">
          <a href="#home" className="nav-item nav-link">
            Home
          </a>
          <a href="#info" className="nav-item nav-link">
            Info
          </a>
          <a href="#products" className="nav-item nav-link">
            Products
          </a>
          <a href="#contact" className="nav-item nav-link">
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
